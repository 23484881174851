import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { MainSection } from "../components/main-section"
import { ThreeCards } from "../components/three-card"
import { ProcessBanner } from "../components/process-banner"
import { HomeBody } from "../components/home-body"
import { PriceCalcBanner } from "../components/price-calc-banner"

class HomeCityTemplate extends React.Component {
    render() {
        const homepage = this.props.data.contentfulHome
        return (
            <Layout>
                <Seo
                    title={"Sell My House In " + homepage.city + ", CA - Trusted " + homepage.city + " Home Buyer"}
                    keywords={[
                        "sell my house in " + homepage.city,
                        "sell my house fast in " + homepage.city,
                        "sell my " + homepage.city + " house",
                        homepage.city + " house buyer",
                        "sell my house for cash in " + homepage.city,
                        "cash house buyer in " + homepage.city
                    ]}
                    location={"/" + homepage.slug}
                    description={"We are a local " + homepage.city + " house buying company, and we can make you a fast & fair offer if you are trying to sell your " + homepage.city + " House. If you want to sell your house fast in " + homepage.city + ", let us give you a FREE no-obligation offer so that you can sell your " + homepage.city + " House today!"}
                />
                <MainSection city={homepage.city} />

                <ProcessBanner />

                <ThreeCards />

                <PriceCalcBanner />

                <HomeBody city={homepage.city} />

            </Layout>
        )
    }
}
export default HomeCityTemplate
export const pageQuery = graphql`
query ContentfulHomeBySlug($slug: String !) {
    site {
        siteMetadata {
            title
        }
    }
    contentfulHome( slug: {eq: $slug }) {
        city
        slug
    }
}
`